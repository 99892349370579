
import { Options, Vue } from "vue-class-component";
import { Action } from "vuex-class";

@Options({
  name: "Login"
})
export default class Login extends Vue {
  @Action("login")
  login!: () => void;
}
